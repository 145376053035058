<template>
  <div class="accounts" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('accounts.title') }}</h1>
    <b-form v-if="viewEntry">
      <ViewButtons @goBack="goBack"/>
      <b-card class="mx-2 mx-md-5" :header="this.$i18n.t('comp.accounts.show.label')">
        <b-form-group :label="$t('comp.accounts.username.label')">
          <b-form-input v-model="form.username" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.homeDirectory.label')">
          <b-form-input v-model="form.homeDirectory" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.uid.label')">
          <b-form-input v-model="form.uid" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.state.label')">
          <b-form-input v-model="form.state" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.project.label')">
          <div class="d-flex">
            <b-form-input class="w-75" v-model="form.project.label" readonly/>
            <b-button class="w-25" @click="forwardProject" variant="primary">
              <b-icon-forward-fill/> {{ $t('comp.accounts.gotoproject', {project: form.project.key}) }}
            </b-button>
          </div>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.otherprojects.label')">
          <b-form-tags v-model="otherProjects" placeholder="" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.user.label')">
          <b-form-input v-if="form.user" v-model="form.user.label" readonly/>
          <b-form-input v-else :value="this.$i18n.t('comp.accounts.show.nouser')" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.prefix.label')">
          <b-form-input v-model="form.prefix" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.validUntil.label')" label-for="valid-until">
          <b-form-datepicker no-flip id="valid-until" :showDecadeNav="showDateTimeDecadeNav" v-model="form.validUntil"
                             v-bind="datePickerLabels || {}" class="mb-2" :value-as-date="true" disabled/>
        </b-form-group>
      </b-card>
      <b-card :header="$t('comp.accounts.publicKeys.title')" class="mx-2 mx-md-5 my-3">
        <PublicKeys :account="form" view="support"/>
      </b-card>
      <CommentCard :targetId="this.form.username.toString()" :showTable="viewEntry" view="support"/>
    </b-form>
    <div v-else class="overflow-auto px-2 px-md-5">
      <div class="row justify-content-between justify-content-md-start mt-lg-3">
        <div class="col-6 col-md-3 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('nhraccounts')" variant="primary">
            <b-icon-funnel-fill v-if="nhrAccountsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.accounts.showNhrOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-3 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('fauaccounts')" variant="primary">
            <b-icon-funnel-fill v-if="fauAccountsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.accounts.showFauOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-3 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('active')" variant="primary">
            <b-icon-funnel-fill v-if="activeAccountsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.accounts.showActive.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-3 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('inactive')" variant="primary">
            <b-icon-funnel-fill v-if="inactiveAccountsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.accounts.showInactive.label') }}
          </b-button>
        </div>
        <div class="col-12 col-md-auto mb-3 ml-md-auto align-self-center">
          <b-form-checkbox v-model="showId" name="showId" switch>
          {{ $t('comp.accounts.showId.label') }}
          </b-form-checkbox>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="accounts-table"/>
      <b-table responsive id="accounts-table" ref="accounts-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" small :items="accountItemProvider" striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="showAccount(data.item)" :title="$t('comp.accounts.show.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-search class="mr-1" variant="primary"/>
          </b-button>
          <b-button v-b-modal="`usage-modal-${data.item.username}`" :title="$t('comp.accounts.showusage.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-bar-chart-line class="mr-1" variant="info"/>
          </b-button>
          <b-modal :id="`usage-modal-${data.item.username}`" :title="$t('comp.accounts.usage.display.label', { account: data.item.username })" size="xl">
            <div>
              <AccountUsageInfo :account="data.item.username"  view="support"/> <!-- type="account" -->
            </div>
          </b-modal>
        </template>
        <template v-slot:cell(mail)="data">
          <a v-if="data.item.mail" :href="`mailto:${data.item.mail}?cc=hpc-support@fau.de&subject=HPC-Portal`">{{ data.item.mail }}</a>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { accountFilterMixin } from '@/mixins/tableFilter.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import { accountServiceForSupportView } from '@/services/account.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import ViewButtons from '@/components/generic/helper/ViewButtons'
import CommentCard from '@/components/generic/helper/CommentCard'
import PublicKeys from '@/components/generic/PublicKeys'
import AccountUsageInfo from '@/components/generic/info/AccountUsageInfo'

export default {
  name: 'Accounts',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin, accountFilterMixin],
  components: {
    PublicKeys,
    TableHeader,
    ViewButtons,
    CommentCard,
    AccountUsageInfo
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      form: null,
      viewEntry: false,
      showDateTimeDecadeNav: true,
      otherProjects: [],
      showId: false
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false, thStyle: 'min-width:100px;' },
        { key: 'username' },
        { key: 'homeDirectory' },
        { key: 'uid' },
        { key: 'project', select: true },
        // { key: 'otherProjects', select: true, array: true },
        { key: 'prefix' },
        { key: 'user', select: true },
        { key: 'mail', sortable: false },
        { key: 'state', state: true },
        { key: 'createdAt', date: true, searchable: false },
        { key: 'validUntil', date: true, searchable: false }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.accounts.${field.key}.label`)
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.select) {
          field.sortKey = field.key + '.label'
          field.formatter = (value, key, item) => {
            if (this.showId === false) {
              return _.has(value, 'label') ? value.label : ''
            } else {
              return _.has(value, 'label') ? `${value.label} (${value.key})` : ''
            }
          }
        }
        if (field.select && field.array) {
          field.formatter = (value) => {
            if (value !== null) {
              let arrayLabel = ''
              value.forEach((elem, index) => {
                arrayLabel += _.has(elem, 'label') ? elem.label : ''
                if (index !== value.length - 1) {
                  arrayLabel += ', '
                }
              })
              return arrayLabel
            }
          }
        }
        if (field.state) {
          field.formatter = (value) => {
            return this.$i18n.t(`states.${value}.label`)
          }
        }
      })
      return fields
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.accounts.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    accountServiceForSupportView.count({ filter: this.filter }).then((response) => (this.rows = response))
    accountServiceForSupportView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      accountServiceForSupportView.count().then((response) => (this.totalRows = response))
      if (this.$refs['accounts-table']) {
        this.$refs['accounts-table'].refresh()
      }
    },
    showAccount (item) {
      this.viewEntry = true
      item.validUntil = item?.validUntil ? new Date(item.validUntil) : null // Format to JSDate for display
      this.form = _.cloneDeep(item)
      this.form.user.label = item?.mail ? `${item.user.label} (${item.mail})` : item.user.label // if mail: reassemble label, else: use as provided
      if (!_.isEmpty(item.otherProjects)) {
        item.otherProjects.forEach(element =>
          this.otherProjects.push(element.label + ' (' + element.key + ')')
        )
      } else {
        this.otherProjects = null
      }
    },
    goBack () {
      this.viewEntry = false
      this.form = null
      this.otherProjects = []
    },
    accountItemProvider (ctx) {
      return accountServiceForSupportView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    forwardProject () {
      this.$emit('gotoProject', this.form.project.key)
      this.goBack()
    }
  }
}
</script>
