<template>
  <div class="invitations" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('invitations.title') }}</h1>
    <div v-if="invitationForm.isVisible">
      <b-form>
        <ViewButtons @goBack="goBack"/>
        <InvitationForm :invitation="invitationForm" :action="invitationForm.action" :view="view"/>
        <CommentCard :targetId="'invite-' + invitationForm.form.invitationId.toString()" :showTable="invitationForm.action === 'show'" :view="view"/>
      </b-form>
    </div>
    <div v-else class="overflow-auto px-2 px-md-5">
      <div class="row justify-content-between justify-content-lg-start mt-lg-3">
        <div class="col-6 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('pending')" variant="primary">
            <b-icon-funnel-fill v-if="pendingFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.invitations.showPendingOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('accepted')" variant="primary">
            <b-icon-funnel-fill v-if="acceptedFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.invitations.showAcceptedOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('otherstates')" variant="primary">
            <b-icon-funnel-fill v-if="otherStatesFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.invitations.showOtherStatesOnly.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="invitations-table"/>
      <b-table responsive id="invitations-table" ref="invitations-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="invitationItemProvider" :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc" small striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="showInvitation(data.item)" :title="$t('comp.invitations.show.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-search class="mr-1" variant="primary"/>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { invitationFilterMixin } from '@/mixins/tableFilter.mixin'
import { invitationServiceForSupportView } from '@/services/invitation.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import ViewButtons from '@/components/generic/helper/ViewButtons'
import InvitationForm from '@/components/generic/form/InvitationForm'
import CommentCard from '@/components/generic/helper/CommentCard'

export default {
  name: 'Invitations',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, invitationFilterMixin],
  components: {
    InvitationForm,
    TableHeader,
    ViewButtons,
    CommentCard
  },
  data () {
    return {
      view: 'support',
      // Table
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      sortBy: 'createdAt',
      sortDesc: true,
      rows: 0,
      totalRows: 0,
      filter: '',
      // Form
      invitationForm: {
        action: null,
        form: null,
        formCache: null,
        isVisible: false
      }
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false },
        { key: 'invitationId' },
        { key: 'forMail' },
        { key: 'forProject', select: true },
        { key: 'forValidity', date: true, searchable: false },
        { key: 'state', state: true },
        { key: 'account' },
        { key: 'supervisor' },
        { key: 'createdBy', select: true },
        { key: 'createdAt', date: true, searchable: false }
      ]

      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.invitations.${field.key}.label`)
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.select) {
          field.sortKey = field.key + '.label'
          field.formatter = (value, key, item) => {
            if (key === 'forProject') {
              return _.has(value, 'label') ? `${value.label} (${value.key})` : ''
            } else {
              return _.has(value, 'label') ? value.label : ''
            }
          }
          field.sortByFormatted = true
        }
        if (field.state) {
          field.formatter = (value) => {
            return this.$i18n.t(`states.${value}.label`)
          }
        }
      })
      return fields
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.invitations.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    invitationServiceForSupportView.count({ filter: this.filter }).then((response) => (this.rows = response))
    invitationServiceForSupportView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      invitationServiceForSupportView.count().then((response) => (this.totalRows = response))
      if (this.$refs['invitations-table']) {
        this.$refs['invitations-table'].refresh()
      }
    },
    showInvitation (item) {
      this.invitationForm.action = 'show'
      item.forValidity = item?.forValidity ? new Date(item.forValidity) : null // Format to JSDate for display
      this.invitationForm.formCache = _.cloneDeep(item)
      this.invitationForm.form = _.cloneDeep(this.invitationForm.formCache)
      this.invitationForm.isVisible = true
    },
    goBack () {
      this.invitationForm.isVisible = false
      this.invitationForm.form = null
      this.invitationForm.formCache = null
      this.invitationForm.action = null
    },
    invitationItemProvider (ctx) {
      return invitationServiceForSupportView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    }
  }
}
</script>

<style scoped>
</style>
