<template>
  <div class="advisor" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t("comp.advisor.title") }}</h1>
    <b-tabs content-class="mt-3" v-model="tabIndex">
      <b-tab :title="$t('users.title')" active>
        <Users/>
      </b-tab>
      <b-tab :title="$t('accounts.title')" lazy>
        <Accounts @gotoProject="handleGoto($event)"/>
      </b-tab>
      <b-tab :title="$t('projects.title')" lazy>
        <Projects :presetFilter="gotoID" @resetPreset="resetGoto"/>
      </b-tab>
      <b-tab :title="$t('requests.title')" lazy v-if="false"> <!-- don't show requests for the moment -->
        <Requests/>
      </b-tab>
      <b-tab :title="$t('invitations.title')" lazy>
        <Invitations/>
      </b-tab>
      <b-tab :title="$t('vouchers.title')" lazy>
        <Vouchers/>
      </b-tab>
      <b-tab :title="$t('export.title')" lazy>
        <Export/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Users from '@/components/advisor/Users.vue'
import Accounts from '@/components/advisor/Accounts.vue'
import Projects from '@/components/advisor/Projects.vue'
import Invitations from '@/components/advisor/Invitations'
import Export from '@/components/advisor/Export'
import Vouchers from '@/components/advisor/Vouchers'
import Requests from '@/components/admin/Requests'

export default {
  name: 'Advisor',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Requests,
    Invitations,
    Users,
    Accounts,
    Projects,
    Export,
    Vouchers
  },
  data () {
    return {
      tabIndex: 0,
      gotoID: ''
    }
  },
  watch: {
    tabIndex (newIndex, oldIndex) {
      if (this.gotoID !== '' && oldIndex === 2) {
        // If tab changed from tabIndex 2 and goto set: reset
        this.resetGoto()
      }
    }
  },
  computed: {
    pageTitle () {
      if (this.messagesAvailable) {
        return `${this.$i18n.t('advisor.title')} | ${this.$i18n.t('app.title')}`
      }
      return null
    }
  },
  methods: {
    handleGoto (ID) {
      this.gotoID = ID
      this.tabIndex = 2
    },
    resetGoto () {
      this.gotoID = ''
    }
  }
}
</script>
