<template>
  <div class="users" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('users.title') }}</h1>
    <!-- FORM READONLY -->
    <b-form v-if="viewEntry">
      <ViewButtons @goBack="goBack"/>
      <b-card class="mx-2 mx-md-5" :header="this.$i18n.t('comp.users.show.label')">
        <b-alert show v-if="isSamlUser" variant="warning">{{ $t('comp.users.isSamlUser.support')}}</b-alert>
        <!-- FIELDS @FORM -->
        <b-form-group :label="$t('comp.users.username.label')">
          <b-form-input v-model="form.username" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.users.givenName.label')">
          <b-form-input v-model="form.givenName" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.users.surname.label')">
          <b-form-input v-model="form.surname" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.users.email.label')">
          <b-form-input v-model="form.email" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.users.roles.label')">
          <b-form-tags v-model="form.localAuthorities" placeholder="" disabled/>
        </b-form-group>
        <b-form-group v-if="isSamlUser" :label="$t('comp.users.affiliations.label')">
          <b-form-input v-model="form.affiliations" readonly/>
        </b-form-group>
        <!-- BOOLEANS @FORM -->
        <b-form-checkbox v-model="form.enabled" name="enabled" switch disabled>
          {{ $t('comp.users.enabled.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.accountLocked" name="accountLocked" switch disabled>
          {{ $t('comp.users.accountLocked.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.accountExpired" name="accountExpired" switch disabled>
          {{ $t('comp.users.accountExpired.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.credentialsExpired" name="credentialsExpired" switch disabled>
          {{ $t('comp.users.credentialsExpired.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.hasGivenConsent" name="hasGivenConsent" switch disabled>
          {{ $t('comp.users.hasGivenConsent.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.hasGivenTouconsent" name="hasGivenTouconsent" switch disabled>
          {{ $t('comp.users.hasGivenTouconsent.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-if="form.subscriptions" v-model="form.subscriptions.nhrsysannounce" name="subscribedToSysannounce" switch disabled>
          {{ $t('comp.users.subscriptions.nhrsysannounce.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-if="form.subscriptions" v-model="form.subscriptions.nhrusers" name="subscribedToUsers" switch disabled>
          {{ $t('comp.users.subscriptions.nhrusers.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-if="form.subscriptions" v-model="form.subscriptions.always" name="subscribedAlways" switch disabled>
          {{ $t('comp.users.subscriptions.always.label') }}
        </b-form-checkbox>
      </b-card>
      <CommentCard :targetId="this.form.username.toString()" :showTable="viewEntry" view="support"/>
    </b-form>
    <!-- TABLE -->
    <div class="overflow-auto px-2 px-md-5" v-else>
      <div class="row justify-content-between justify-content-md-start mt-lg-3">
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('useronly')" variant="primary">
            <b-icon-funnel-fill v-if="userUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showUserOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('manager')" variant="primary">
            <b-icon-funnel-fill v-if="managerUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showManagerOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('pionly')" variant="primary">
            <b-icon-funnel-fill v-if="piUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showPiOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('advisor')" variant="primary">
            <b-icon-funnel-fill v-if="advisorUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showAdvisorOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('support')" variant="primary">
            <b-icon-funnel-fill v-if="supportUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showSupportOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('admin')" variant="primary">
            <b-icon-funnel-fill v-if="adminUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showAdminOnly.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="users-table"/>
      <b-table responsive id="users-table" ref="users-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="userItemProvider" small striped hover>
        <template v-slot:cell(actions)="data">
          <div style="max-width: 100px;">
            <b-button @click="showUser(data.item)" :title="$t('comp.users.show.label')" variant="light"
                      size="sm" class="mr-1">
              <b-icon-search class="mr-1" variant="primary"/>
            </b-button>
            <b-button v-b-modal="`usage-modal-${data.item.username}`" :title="$t('comp.users.showusage.label')" variant="light"
                      size="sm" class="mr-1">
              <b-icon-bar-chart-line class="mr-1" variant="info"/>
            </b-button>
            <b-button v-b-modal="`accounts-modal-${data.item.username}`" :title="$t('comp.users.showaccounts.label')" variant="light"
                      size="sm" class="mt-1 mr-1">
              <b-icon-person-circle class="mr-1" variant="primary"/>
            </b-button>
          </div>
          <b-modal :id="`usage-modal-${data.item.username}`" :title="$t('comp.users.usage.display.label', { user: data.item.username })" size="xl">
            <div>
              <UserUsageInfo :user="data.item.username" view="support"/>
            </div>
          </b-modal>
          <b-modal :id="`accounts-modal-${data.item.username}`" :title="$t('comp.users.useraccounts.label', { user: data.item.username, mail: data.item.email })" size="xl">
            <div>
              <UserAccounts :user="data.item" view="support"/>
            </div>
          </b-modal>
        </template>
        <template v-slot:cell(email)="data">
          <a :href="`mailto:${data.item.email}?cc=hpc-support@fau.de&subject=HPC-Portal`">{{ data.item.email }}</a>
        </template>
        <template v-slot:cell(lastLoginType)="data">
          <div v-if="data.item.lastLoginType === 'local'">
            {{ data.item.lastLoginType }}
          </div>
          <b-button v-if="data.item.lastLoginType === 'saml'" :id="`remoteentity-popover${data.item.username}`" variant="light" class="px-2 py-1" style="border-color:darkgray">
            {{ data.item.lastLoginType }}
          </b-button>
          <b-popover v-if="data.item.lastLoginType === 'saml'" :target="`remoteentity-popover${data.item.username}`" triggers="hover" placement="right">
            <template #title>
              <b-icon-globe2 class="mr-1"/>
              {{ $t('comp.users.remoteentity.label') }}
            </template>
            <p v-if="data.item.saml"> {{ data.item.saml.remoteEntityID }} </p>
            <p v-else> {{ $t('comp.users.remoteentity.missing') }} </p>
          </b-popover>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { userFilterMixin } from '@/mixins/tableFilter.mixin'
import { userServiceForSupportView } from '@/services/user.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import ViewButtons from '@/components/generic/helper/ViewButtons'
import CommentCard from '@/components/generic/helper/CommentCard'
import UserAccounts from '@/components/generic/helper/UserAccounts'
import UserUsageInfo from '@/components/generic/info/UserUsageInfo'

export default {
  name: 'Users',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, userFilterMixin],
  components: {
    TableHeader,
    ViewButtons,
    CommentCard,
    UserAccounts,
    UserUsageInfo
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      form: null,
      viewEntry: false
    }
  },
  watch: {
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false, thStyle: 'min-width:100px;' },
        { key: 'username' },
        { key: 'givenName' },
        { key: 'surname' },
        { key: 'email' },
        { key: 'lastLogin', date: true, searchable: false },
        { key: 'lastLoginType' },
        { key: 'enabled', boolean: true, searchable: false },
        { key: 'hasAccounts', array: true, sortable: false, searchable: false },
        { key: 'localAuthorities', array: true, capitalize: true, sortable: false },
        { key: 'touconsentDate', date: true, searchable: false }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.users.${field.key}.label`)
        }
        if (field.boolean) {
          field.formatter = (value, key, item) => {
            return value ? this.$i18n.t('yes.label') : this.$i18n.t('no.label')
          }
          field.sortByFormatted = true
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.array) {
          field.formatter = (value) => {
            if (value !== null) {
              let arrayLabel = ''
              value.forEach((elem, index) => {
                if (field.capitalize) {
                  arrayLabel += elem.charAt(0).toUpperCase() + elem.slice(1)
                } else {
                  arrayLabel += elem
                }
                if (index !== value.length - 1) {
                  arrayLabel += ', '
                }
              })
              return arrayLabel
            }
          }
        }
      })
      return fields
    },
    isSamlUser () {
      if (this.form.lastLoginType != null && this.form.lastLoginType === 'saml') {
        return true
      }
      return false
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.users.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    userServiceForSupportView.count({ filter: this.filter }).then((response) => (this.rows = response))
    userServiceForSupportView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      userServiceForSupportView.count().then((response) => (this.totalRows = response))
      if (this.$refs['users-table']) {
        this.$refs['users-table'].refresh()
      }
    },
    showUser (item) {
      this.viewEntry = true
      this.form = _.cloneDeep(item)
      // Clean special table field: Accounts will be listed differently
      this.$delete(this.form, 'hasAccounts')
    },
    goBack () {
      this.viewEntry = false
      this.form = null
    },
    userItemProvider (ctx) {
      return userServiceForSupportView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    }
  }
}
</script>
