<template>
  <div class="export" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('export.title') }}</h1>
    <b-card class="mb-4">
      <template #header>
        <b-row>
          <b-col cols="12">
            <b-icon-file-earmark-spreadsheet class="mr-1"/>
            {{ $t('comp.export.usagestats.info') }}
          </b-col>
        </b-row>
        <hr class="my-2"/>
        <b-row class="mb-2" align-v="center" cols="1" cols-md="3">
          <b-col>
            <ProjectSelect class="mb-2 mb-md-0 w-100" view="advisor" :loadValidity="false" :selected-projects.sync="usageProjects"/>
          </b-col>
          <b-col>
            <div>
              {{ $t('comp.export.usagestats.from') }}
              <b-form-select class="mb-2 mb-md-0 ml-0 ml-md-2 w-auto" v-model="exportUsageFrom.mon"  :options="monthOptions" :disabled="qinto || !projectsSelected"/>
              <b-form-select class="mb-2 mb-md-0 ml-2 w-25" v-model="exportUsageFrom.year" :options="exportYearOptions" :disabled="qinto || !projectsSelected"/>
              <b-button @click="resetSelection('from')" variant="secondary" class="ml-2 w-25" :title="$t('comp.export.usagestats.reset')" :disabled="qinto">
                <b-icon-arrow-counterclockwise shift-v="-1"/> <div class="d-inline d-md-none d-xl-inline"> {{ $t('comp.export.usagestats.reset') }} </div>
              </b-button>
            </div>
          </b-col>
          <b-col>
              <div>
              {{ $t('comp.export.usagestats.until') }}
              <b-form-select class="mb-3 mb-md-0 ml-2 w-auto" v-model="exportUsageTo.mon"  :options="monthOptions" :disabled="qinfrom || !projectsSelected"/>
              <b-form-select class="mb-3 mb-md-0 ml-2 w-25" v-model="exportUsageTo.year" :options="exportYearOptions" :disabled="qinfrom || !projectsSelected"/>
              <b-button @click="resetSelection('to')" variant="secondary" class="ml-2 w-25" :title="$t('comp.export.usagestats.reset')" :disabled="qinfrom">
                <b-icon-arrow-counterclockwise shift-v="-1"/> <div class="d-inline d-md-none d-xl-inline"> {{ $t('comp.export.usagestats.reset') }} </div>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row align-v="center" cols="1" cols-sm="2">
          <b-col>
            <b-button @click="getUsageStatsAdvisor('advised')" variant="primary" class="w-100" :disabled="projectsSelected">
              {{ $t('comp.export.usagestats.advised.label') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button @click="getUsageStatsAdvisor('specified')" variant="primary" class="w-100" :disabled="!projectsSelected">
              {{ $t('comp.export.usagestats.label') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-form-textarea v-model="usageStats" rows="6" max-rows="12"/>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import yearSelectMixin from '@/mixins/yearSelect.mixin'
import ProjectSelect from '@/components/generic/select/ProjectSelect'
import { usageServiceForAdvisorView } from '@/services/usage.service'

export default {
  name: 'Export',
  i18n: {
    messages: {}
  },
  mixins: [
    i18nMixin,
    yearSelectMixin
  ],
  components: {
    ProjectSelect
  },
  data () {
    return {
      usageProjects: [],
      usageStats: '',
      usageFrom: { mon: null, year: null },
      usageTo: { mon: null, year: null },
      exportUsageFrom: { mon: null, year: null },
      exportUsageTo: { mon: null, year: null },
      monthOptions: [],
      exportYearOptions: []
    }
  },
  beforeUpdate () {
    this.monthOptions = [
      { value: null, text: this.$i18n.t('comp.export.usagestats.month.placeholder'), disabled: true },
      {
        label: this.$i18n.t('comp.export.usagestats.month.label'),
        options: [
          { value: 'Jan', text: this.$i18n.t('comp.export.usagestats.month.jan') },
          { value: 'Feb', text: this.$i18n.t('comp.export.usagestats.month.feb') },
          { value: 'Mar', text: this.$i18n.t('comp.export.usagestats.month.mar') },
          { value: 'Apr', text: this.$i18n.t('comp.export.usagestats.month.apr') },
          { value: 'May', text: this.$i18n.t('comp.export.usagestats.month.may') },
          { value: 'Jun', text: this.$i18n.t('comp.export.usagestats.month.jun') },
          { value: 'Jul', text: this.$i18n.t('comp.export.usagestats.month.jul') },
          { value: 'Aug', text: this.$i18n.t('comp.export.usagestats.month.aug') },
          { value: 'Sep', text: this.$i18n.t('comp.export.usagestats.month.sep') },
          { value: 'Oct', text: this.$i18n.t('comp.export.usagestats.month.oct') },
          { value: 'Nov', text: this.$i18n.t('comp.export.usagestats.month.nov') },
          { value: 'Dec', text: this.$i18n.t('comp.export.usagestats.month.dec') }
        ]
      },
      {
        label: this.$i18n.t('comp.export.usagestats.quarter.label'),
        options: [
          { value: 'Q1', text: this.$i18n.t('comp.export.usagestats.month.qone') },
          { value: 'Q2', text: this.$i18n.t('comp.export.usagestats.month.qtwo') },
          { value: 'Q3', text: this.$i18n.t('comp.export.usagestats.month.qthree') },
          { value: 'Q4', text: this.$i18n.t('comp.export.usagestats.month.qfour') }
        ]
      }
    ]
    this.exportYearOptions = [
      { value: null, text: this.$i18n.t('comp.export.usagestats.year.placeholder'), disabled: true },
      ...this.yearOptions
    ]
  },
  watch: {
    'exportUsageFrom.mon': function (newValue, oldValue) {
      if (newValue !== null) this.handleQuarter(this.exportUsageFrom, 'from')
    },
    'exportUsageFrom.year': function (newValue, oldValue) {
      if (newValue !== null) this.handleQuarter(this.exportUsageFrom, 'from')
    },
    'exportUsageTo.mon': function (newValue, oldValue) {
      if (newValue !== null) this.handleQuarter(this.exportUsageTo, 'to')
    },
    'exportUsageTo.year': function (newValue, oldValue) {
      if (newValue !== null) this.handleQuarter(this.exportUsageTo, 'to')
    }
  },
  computed: {
    projectsSelected () {
      return _.isEmpty(this.usageProjects) === false
    },
    qinfrom () {
      if (this?.exportUsageFrom?.mon && this.exportUsageFrom.mon.match(/^Q[1-4]{1}$/)) {
        return true
      } else {
        return false
      }
    },
    qinto () {
      if (this?.exportUsageTo?.mon && this.exportUsageTo.mon.match(/^Q[1-4]{1}$/)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    handleQuarter (exportUsage, dir) {
      if (!exportUsage?.year) {
        // Local fillin
        exportUsage.year = this.thisYear
        // Global fillin
        if (dir === 'from') this.exportUsageFrom.year = this.thisYear
        else this.exportUsageTo.year = this.thisYear
      }

      switch (exportUsage?.mon) {
        case 'Q1':
          this.usageFrom = { mon: 'Jan', year: exportUsage.year }
          this.usageTo = { mon: 'Mar', year: exportUsage.year }
          break
        case 'Q2':
          this.usageFrom = { mon: 'Apr', year: exportUsage.year }
          this.usageTo = { mon: 'Jun', year: exportUsage.year }
          break
        case 'Q3':
          this.usageFrom = { mon: 'Jul', year: exportUsage.year }
          this.usageTo = { mon: 'Sep', year: exportUsage.year }
          break
        case 'Q4':
          this.usageFrom = { mon: 'Oct', year: exportUsage.year }
          this.usageTo = { mon: 'Dec', year: exportUsage.year }
          break
        default:
          if (dir === 'from') {
            this.usageFrom = { mon: exportUsage.mon, year: exportUsage.year }
            this.usageTo = this.exportUsageTo
          } else {
            this.usageFrom = this.exportUsageFrom
            this.usageTo = { mon: exportUsage.mon, year: exportUsage.year }
          }
      }
    },
    resetSelection (dir) {
      if (dir === 'from') {
        this.exportUsageFrom = { mon: null, year: null }
        this.usageFrom = { mon: null, year: null }
        this.usageTo = this.exportUsageTo // Reset to visible selection after possible quarter selection
      } else {
        this.exportUsageTo = { mon: null, year: null }
        this.usageTo = { mon: null, year: null }
        this.usageFrom = this.exportUsageFrom // Reset to visible selection after possible quarter selection
      }
    },
    getUsageStatsAdvisor (type) {
      if (type === 'specified') {
        if (this.usageFrom.mon !== null && this.usageTo.mon !== null &&
          this.usageFrom.year !== null && this.usageTo.year !== null) { // this.usageProjects.length > 0
          const statsRequest = { projects: this.usageProjects, from: this.usageFrom, to: this.usageTo }
          usageServiceForAdvisorView.getUsageStats(statsRequest).then((csv) => {
            this.usageStats = csv
            // this.usageFrom = { mon: null, year: null }
            // this.usageTo = { mon: null, year: null }
            this.usageProjects = []
          }).catch(error => {
            const parsed = JSON.parse(error.request.response)
            this.usageStats = parsed.error
            console.error(error)
          })
        } else {
          this.usageStats = this.$i18n.t('comp.export.usagestats.form.info')
          console.log('Fill usage stats form first!')
        }
      } else if (type === 'advised') {
        const statsRequest = { projects: [], from: { mon: 'Adv', year: 0 }, to: { mon: 'Adv', year: 0 } }
        usageServiceForAdvisorView.getUsageStats(statsRequest).then((csv) => {
          this.usageStats = csv
          // this.usageFrom = { mon: null, year: null }
          // this.usageTo = { mon: null, year: null }
          this.usageProjects = []
        }).catch(error => {
          const parsed = JSON.parse(error.request.response)
          this.usageStats = parsed.error
          console.error(error)
        })
      } else {
        this.usageStats = 'Unknown Request Type'
      }
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
