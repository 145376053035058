<template>
  <div class="overflow-auto mx-2 mx-md-5">
    <div class="d-md-flex my-3">
      <b-button @click="$emit('goBack')" variant="secondary" class="mr-1">
        <b-icon-arrow-return-left shift-v="-2" class="mr-1"/>{{ $t('back.label') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
export default {
  name: 'ViewButtons',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  props: {}
}
</script>

<style scoped>
</style>
