<template>
  <div class="export" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('export.title') }}</h1>
    <b-card>
      <template #header>
        <b-row>
          <b-col cols="12">
            <b-icon-mailbox class="mr-1"/>
            {{ $t('comp.export.mail.info') }}
          </b-col>
        </b-row>
        <hr class="my-2"/>
        <b-row class="mb-2">
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('pi')"  variant="primary" class="w-100">
             {{ $t('comp.export.pimail.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('manager')" variant="primary" class="w-100">
              {{ $t('comp.export.managermail.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('advisor')" variant="primary" class="w-100">
              {{ $t('comp.export.advisormail.label') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('nhrpi')"  variant="primary" class="w-100">
             {{ $t('comp.export.nhrpimail.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('nhrmanager')" variant="primary" class="w-100">
              {{ $t('comp.export.nhrmanagermail.label') }}
            </b-button>
          </b-col>
          <b-col cols="4" cols-md="auto" class="pr-0">
            <b-button @click="loadMail('nhrinactive')" variant="primary" class="w-100">
              {{ $t('comp.export.nhrinactivemail.label') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-form-textarea v-model="mails" rows="6" max-rows="12"/>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import { exportServiceForSupportView } from '@/services/export.service'

export default {
  name: 'Export',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {},
  data () {
    return {
      mails: ''
    }
  },
  methods: {
    loadMail (type) {
      const params = { mailFor: type }
      exportServiceForSupportView.mails(params).then((mails) => {
        this.mails = mails
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
